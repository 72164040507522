import axios from '@/utils/axios';
const apiUrl = {
  //主页的接口
  getAdvLogList: '/extractor/tt/adv/log/search',
  exportExcel: '/extractor/tt/adv/log/export',
};

//查询接口
export function getAdvLogListData(params) {
  return axios.post(apiUrl.getAdvLogList, params);
}
export function exportExcel(param) {
  return axios.post(apiUrl.exportExcel, param, {
    timeout: 30 * 1000,
    withCredentials: true,
    responseType: 'blob',
  });
}