<template>
  <div class="media w100 h100">
    <div class="filter_row">
      <a-tabs v-model:activeKey="activeTabKey" @change="onTabChange">
        <a-tab-pane key="TT" tab="巨量引擎" force-render></a-tab-pane>
        <!--        <a-tab-pane key="GDT" tab="腾讯广告"></a-tab-pane>
                <a-tab-pane key="KS" tab="磁力引擎"></a-tab-pane>
                <a-tab-pane key="QC" tab="巨量千川"></a-tab-pane>-->
      </a-tabs>
      <a-form :model="filterConditions">
        <a-row type="flex">
          <div style="flex: 1">
            <a-row class="media-choice">
              <a-col :span="4">
                <a-form-item label='广告账户ID' :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
                  <a-input v-model:value="filterConditions.advIds" placeholder="批量查询ID请用空格隔开"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label='' :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                  <a-input v-model:value="filterConditions.objectId" placeholder="请输入操作对象ID"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="4">
                <a-form-item label='' :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                  <a-select v-model:value="filterConditions.objectType" placeholder="请选择对象类型" allow-clear style="width: 100%">
                    <a-select-option value="广告主">广告主</a-select-option>
                    <a-select-option value="项目">项目</a-select-option>
                    <a-select-option value="广告">广告</a-select-option>
                    <a-select-option value="广告计划">广告计划</a-select-option>
                    <a-select-option value="广告创意">广告创意</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item label='' :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                  <a-select v-model:value="filterConditions.contentTitle" placeholder="请选择操作动作" allow-clear style="width: 100%">
                    <a-select-option value="新建">新建</a-select-option>
                    <a-select-option value="修改">修改</a-select-option>
                    <a-select-option value="审核">审核</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label='操作时间' :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                  <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" :allow-clear="false" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDate" @change="onDateRangeChange" :disabled="tableLoading" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="media-choice">
              <a-col :span="8">
                <a-form-item label='操作内容' :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                  <a-input v-model:value="filterConditions.contentLog" placeholder="操作内容"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label='' :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                  <a-input v-model:value="filterConditions.operator" placeholder="请输入操作人"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label='' :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                  <a-input v-model:value="filterConditions.optIp" placeholder="请输入操作IP"></a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-space>
            <a-button type="primary" @click="onSearchBtnClick" :disabled="tableLoading">筛选</a-button>
            <a-button @click="resetFilter" :disabled="tableLoading">重置</a-button>
            <a-button :loading="excelExporting" :disabled="tableLoading" @click="onExportExcelBtnClick">导出Excel</a-button>
          </a-space>
        </a-row>
      </a-form>
    </div>
    <div class="table_wrapper">
      <a-space :size="10" class="table_operation">
      </a-space>
      <a-table :columns="advLogTableColumns" :data-source="advLogTableData" class="w100" size="small" bordered :loading="tableLoading" :pagination="tablePagination" @change="onTableChange">

      </a-table>
    </div>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
  getManagerAccountListData,
} from '@/api/mediaAccount/mediaAccount.js';
import { Empty, message } from 'ant-design-vue';
import { mapState } from 'vuex';
import { ref } from 'vue';
import { getAdvLogListData } from '@/api/tools/advlog';
import { exportExcel } from '@/api/tools/advlog';
export default {
  data() {
    // 从推广管理账户批量分配账号带过来的数据
    const activeTabKey = this.$route?.params?.channel || 'TT';
    const dateRangeFormat = 'YYYY-MM-DD';
    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    const disabledDate = current => {
      return current && current > moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = ref([moment().startOf('month'), moment()]);
    const excelExporting = ref(false);
    return {
      foldFilter: true, //是否折叠筛选区
      moment,
      dateRangeFormat,
      dateRanges,
      disabledDate,
      defaultRange,
      excelExporting,
      activeTabKey: activeTabKey,
      locale,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      distributionOperatorDisabled: true, //会话框运营人员禁用
      tableLoading: false,
      selectedRowKeys: [], // 选中的行的id
      selectedRows: [],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['30', '50', '100'],
      },
      advLogTableColumns: [
        {
          title: '操作时间',
          width: 100,
          dataIndex: 'logTime',
          key: 'logTime',
        },
        {
          title: '操作人',
          width: 100,
          dataIndex: 'operator',
          key: 'operator',
        },
        {
          title: '广告主ID',
          width: 100,
          key: 'advId',
          dataIndex: 'advId',
        },
        {
          title: '操作对象类型',
          width: 100,
          dataIndex: 'objectType',
          key: 'objectType',
        },
        {
          title: '操作对象ID',
          width: 100,
          dataIndex: 'objectId',
          key: 'objectId',
        },
        {
          title: '操作对象名称',
          width: 100,
          dataIndex: 'objectName',
          key: 'objectName',
        },
        {
          title: '操作动作',
          width: 100,
          key: 'contentTitle',
          dataIndex: 'contentTitle',
        },
        {
          title: '操作结果',
          width: 450,
          key: 'contentLog',
          dataIndex: 'contentLog',
        },
        {
          title: '操作IP',
          width: 150,
          key: 'optIp',
          dataIndex: 'optIp',
        },
      ],
      advLogTableData: [],
      managerAccountListTemp:[],
      filterConditions: {
        advIds: '',
        advName: '',
        objectId: '',
        objectName: '',
        objectType: '',
        contentTitle: '',
        optIp: '',
        operator: '',
        contentLog: '',
        startDate: moment().startOf('month').format(dateRangeFormat),
        endDate:moment().format(dateRangeFormat),
      },
      labelCol: { sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 6 } },
      wrapperCol: { sm: { span: 14 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 14 }, xxl: { span: 16 } },
      rowData: {}, //当前行的数据
      filterConditionMap: {},
    };
  },
  inject: ['$bus'],
  computed: {
    periodComputed(){
      return  moment(new Date()).format("YYYY-MM") ;
    },
    ...mapState({ userJobs: state => state.oauth.userInfo.jobs }),
    funcPoints() {
      return localStorage.getItem('funcPoint');
    },
  },
  created() {
    this.getManagerAccountListTemp();
    this.getAdvLogListData();
  },
  methods: {
    getManagerAccountListTemp(){
      let mediaChannelTemp = this.activeTabKey;
      getManagerAccountListData(mediaChannelTemp).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
        }
      });
    },
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.filterConditions.startDate = dateString[0];
      this.filterConditions.endDate = dateString[1];
      this.tablePagination.page = '1';
      this.getAdvLogListData();
    },
    onTabChange() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.resetFilter();
    },
    getAdvLogListData() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      this.filterConditions.advIds = this.filterConditions.advIds.trim();
      this.filterConditions.objectId = this.filterConditions.objectId.trim();
      this.filterConditions.optIp = this.filterConditions.optIp.trim();
      this.filterConditions.contentLog = this.filterConditions.contentLog.trim();
      this.filterConditions.operator = this.filterConditions.operator.trim();
      let params = {
        mediaChannel: this.activeTabKey,
        startDate: this.filterConditions.startDate,
        endDate: this.filterConditions.endDate,
        ...this.tablePagination,
        ...this.filterConditions,
      };
      getAdvLogListData(params).then(res => {
        if (res.code == 0) {
          this.advLogTableData = res.data.list;
          this.tablePagination.current = res.data.currPage;
          this.tablePagination.pageSize = res.data.pageSize;
          this.tablePagination.total = res.data.totalCount;
        }
      }).catch(error => {
        message.error(error.message,3)
      }).finally(() => {
        this.tableLoading = false;
      })
    },
    // 表格翻页
    onTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.getAdvLogListData();
    },
    async onExportExcelBtnClick() {
      this.excelExporting = true;
      this.filterConditions.advIds = this.filterConditions.advIds.trim();
      this.filterConditions.objectId = this.filterConditions.objectId.trim();
      this.filterConditions.optIp = this.filterConditions.optIp.trim();
      this.filterConditions.contentLog = this.filterConditions.contentLog.trim();
      this.filterConditions.operator = this.filterConditions.operator.trim();
      let params = {
        mediaChannel: this.activeTabKey,
        startDate: this.filterConditions.startDate,
        endDate: this.filterConditions.endDate,
        ...this.tablePagination,
        ...this.filterConditions,
      };
      exportExcel(params).then(res => {
        let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = `广告主操作日志.xlsx`;
        link.click();
        URL.revokeObjectURL(objectUrl);
      }).catch(error => {
        message.error(error.message,3)
      }).finally(() => {
        this.excelExporting = false;
      })
    },
    onSearchBtnClick() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (this.tablePagination.page !== '1') {
        this.tablePagination = {};
        this.tablePagination.limit = '10';
        this.tablePagination.page = '1';
      }
      this.getAdvLogListData();

    },
    resetFilter() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = '1';
      this.filterConditions = {
        operatorId: null, mdaIds: '',
        mdaNames: '',
        selectType: 'account',
        period: moment(new Date()).format("YYYY-MM") ,//素材清理月份
      },
        this.getAdvLogListData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
};
</script>

<style lang="less">
@import url('./index.less');
</style>
<style lang="less" scoped>
.media {
  display: flex;
  flex-direction: column;
  :deep(.filter_row) {
    padding-top: 0;
    padding-bottom: 6px;
    .ant-tabs-top-bar {
      margin-bottom: 8px;
    }
  }
  .table_wrapper {
    display: flex;
    flex-direction: column;
    .table_operation {
      margin-bottom: 10px;
    }
    :deep(.media_account_table) {
      flex-grow: 1;
      .ant-table-scroll .ant-table-body {
        max-height: calc(100vh - 390px) !important;
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
        margin-top: 12px;
      }
    }
  }
}
</style>